<template>
  <b-tooltip :target="target" :triggers="triggers" :placement="placement" :variant="variant">
    <table class="b-table text-left">
      <tbody>
        <tr>
          <td :class="labelClass">First name</td>
          <td>{{contact.firstname}}</td>
        </tr>
        <tr>
          <td :class="labelClass">Last name</td>
          <td>{{contact.lastname}}</td>
        </tr>
        <tr>
          <td :class="labelClass">Mobile</td>
          <td>{{phone}}</td>
        </tr>
        <tr>
          <td :class="labelClass">E-mail</td>
          <td>{{contact.contact ? contact.contact.email : ''}}</td>
        </tr>
        <tr>
          <td :class="labelClass">Address</td>
          <td>{{address}}</td>
        </tr>
      </tbody>
    </table>
  </b-tooltip>
</template>

<script>
import get from 'lodash.get';

export default {
  data(){
    return {
      labelClass: 'font-weight-bold pr-2 noBr',
    };
  },
  computed: {
    phone() {
      const cellPhone = get(this, 'contact.contact.cellPhone');
      const homePhone = get(this, 'contact.contact.homePhone');
      return cellPhone || homePhone || '';
    },
    address() {
      return [
        get(this, 'contact.contact.street'),
        get(this, 'contact.contact.city'),
        get(this, 'contact.contact.zip')
      ].filter(data => data).join(', ');
    },
  },
  props: ['contact', 'target', 'triggers', 'placement', 'variant'],
}
</script>

<style lang="scss">
  .noBr {
    white-space: nowrap;
  }

  .tooltip-inner {
    max-width: 400px;
  }
</style>
