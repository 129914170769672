<template>
  <section class="mt-3 p-3 border rounded position-relative">
    <div class="d-flex mb-4">
      <div class="flex-grow" :class="{ submitted: match.submitted }">
        <Headline level="2" size="4" class="mb-1 text-primary text-uppercase">{{ match.title }}</Headline>
        <p>{{ matchday }}</p>
      </div>
      <div class="pl-3 pt-1 font-weight-bold total" :class="{ submitted: match.submitted }" v-if="match.total && !match.submitRequest">
        <span v-if="isComplimentaryMode" class="mr-1">Total tickets:</span>
        <span v-if="isComplimentaryMode">{{ match.total || 0 }}</span>
        <span v-else>{{ match.total.toFixed(2) }}</span>
        <span v-if="!isComplimentaryMode" class="ml-1">{{ match.currency }}</span>
      </div>
      <div class="pl-3">
        <pulse-loader
          :loading="Boolean(match.submitRequest)"
          :color="loaderColor"
          v-if="match.submitRequest"
        />
        <b-icon-check-circle
          variant="primary"
          class="icon-submitted"
          v-else-if="match.submitted"
        />
        <Button
          class="button-delete"
          @click="$emit('removeMatch', match)"
          icon="BIN_OUTLINE"
          type="round"
          v-else
        />
      </div>
    </div>

    <b-row :class="{ submitted: match.submitted }">
      <b-col xl="3">
        <b-row cols="2">
          <b-col :class="columnClass">
            <label :class="labelClass">Match Date</label>
            <p>{{ getMatchKickoffDate(match.startDateTime) }}</p>
          </b-col>
          <b-col :class="columnClass">
            <label :class="labelClass">Venue</label>
            <p>{{ match.venue ? match.venue.localTitle : '' }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="4" :class="separatorClass" v-if="!showLoader">
        <b-row cols="2">
          <b-col v-for="type in contactTypes" :key="type.key" :class="columnClass">
            <label :class="labelClass">{{ type.label }}</label>
            <p v-if="request[type.key]">
              {{ request[type.key].firstname }} {{ request[type.key].lastname }}
              <b-icon-eye
                variant="primary"
                :id="'contactTooltip' + match.matchId + type.key"
                class="pointer"
                @click="$root.$emit('bv::hide::tooltip')"
              />
              <TicketsContactTooltip
                :target="'contactTooltip' + match.matchId + type.key"
                :contact="request[type.key]"
                triggers="click"
                placement="bottom"
                variant="primary"
              />
            </p>
            <router-link :to="{name: type.route}" class="border border-danger rounded px-2 py-1 text-danger noBr d-inline-block d-xl-inline" v-else>
              Contact missing
            </router-link>
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="5" :class="separatorClass" v-if="!showLoader">
        <b-row cols="3" class="mb-xl-n2" v-if="match.zones && match.zones.length">
          <b-col :class="columnClass" v-for="zone in match.zones" :key="zone.ticketZoneId">
            <label :class="labelClass" class="text-primary">{{ zone.name }}</label>
            <b-row align-v="center" class="mt-xl-n2" no-gutters>
              <b-col cols="7">
                <Input
                  :value="zone.quantity"
                  type="number"
                  min="0"
                  :max="(isComplimentaryMode && match.hasComplimentaryQuota) ? (zone.quota || 0) : 10000"
                  step="1"
                  class="m-0"
                  :inputClass="{ 'px-2': true, 'py-1': true, 'border-danger': (isComplimentaryMode && match.hasComplimentaryQuota && zone.quantity > (zone.quota || 0)) }"
                  :disableInput="match.submitted"
                  @input="updateZoneQuantity(zone, $event)"
                />
              </b-col>
              <b-col cols="5" v-if="isComplimentaryMode && match.hasComplimentaryQuota">
                <span class="mx-1">/</span>
                <span>{{ zone.quota || 0 }}</span>
              </b-col>
              <b-col cols="5" v-else-if="!isComplimentaryMode">
                <span class="mx-1">{{ zone.price ? zone.price.toFixed(2) : '' }}</span>
                <span>{{ zone.currency }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="text-center text-danger font-weight-bold my-4 my-xl-2" v-else>
          Sorry, no tickets available
        </div>
      </b-col>
      <b-col xl="9" :class="separatorClass" class="d-flex justify-content-center align-items-center py-4 py-xl-0" v-if="showLoader">
        <pulse-loader
          :loading="showLoader"
          :color="loaderColor"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import get from 'lodash.get';
import {DtoApiGetComplimentaryTicketsRequest} from "@/classes/dto/api/ticket/dto.api.get-complimentary-tickets.request";
import {DtoApiGetRegularTicketsRequest} from "@/classes/dto/api/ticket/dto.api.get-regular-tickets.request";
import Headline from "@/components/Headline/Headline.component.vue";
import Input from "@/components/Inputs/Input.component.vue";
import moment from "moment/moment";
import Button from "@/components/Button/Button.component.vue";
import RouterNames from "@/router/route.names";
import TicketsContactTooltip from "@/components/Tickets/contacts/TicketsContactTooltip.component.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: {
    Button,
    Input,
    Headline,
    TicketsContactTooltip,
    PulseLoader,
  },
  data(){
    return {
      request: null,
      contactTypes: [
        {
          key: 'mainContact',
          label: 'Main Contact',
          route: RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.MainContacts,
        },
        {
          key: 'deliveryContact',
          label: 'Delivery Contact',
          route: RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.DeliveryAddress,
        },
      ],
      columnClass: 'py-4 py-xl-2',
      labelClass: 'd-block font-weight-bold mb-3',
      separatorClass: 'separator-border mx-2 mx-xl-0 px-2 px-xl-3',
      loaderColor: '#00A9A0',
      showLoader: true,
    };
  },
  props: ['match', 'ticketsType'],
  computed: {
    organizationId() {
      return get(this, '$store.state.user.account.id');
    },
    isValid(){
      return (0 === this.match.zones.filter(item => item.quantity > item.quota).length);
    },
    isComplimentaryMode(){
      return (this.ticketsType === 'complimentary');
    },
    matchday(){
      return [
        this.match?.matchday?.stage?.name,
        this.match?.matchday?.name,
      ].filter(text => text).join(' - ');
    },
  },
  watch: {
    match(){
      this.init()
    },
    ticketsType(){
      this.request = this.initRequest();
      this.init();
    },
  },
  created(){
    this.request = this.initRequest();
    this.init()
  },
  methods: {
    initRequest(){
      if(this.isComplimentaryMode){
        return new DtoApiGetComplimentaryTicketsRequest();
      }
      return new DtoApiGetRegularTicketsRequest();
    },
    async init() {
      const {matchId} = this.match;
      const {organizationId} = this;
      const zoneQuantities = Object.fromEntries((this.match.zones || []).map(zone => [zone.ticketZoneId, zone.quantity]));

      this.showLoader = true;

      this.match.zones = [];
      this.request = await this.request.$get(organizationId, matchId);

      this.match.mainContact = this.request.mainContact;
      this.match.deliveryContact = this.request.deliveryContact;
      this.match.hasComplimentaryQuota = this.request.organization?.hasComplimentaryQuota;

      if (this.request.availableTickets && this.request.availableTickets.length) {
        this.match.zones = this.request.availableTickets.map(ticket => {
          const {quota, currency, ticketZoneId, name} = ticket;
          const price = Number(ticket.price);
          const quantity = Number(zoneQuantities[ticketZoneId]) || 0;
          this.match.currency = currency;

          return {name, quota, price, currency, quantity, ticketZoneId};
        })
      }

      this.calculateTotal();

      this.showLoader = false;
    },
    getMatchKickoffDate(startDateTime) {
      return moment(startDateTime).add(8, 'hours').format('DD.MM.YYYY');
    },
    updateZoneQuantity(zone, quantity) {
      if(!zone){
        return;
      }

      //for some reason chrome gets an Event here while firefox get the new value so conversion is needed
      if(quantity instanceof Event) {
        quantity = quantity.target?.value;
      }

      this.$set(zone, 'quantity', parseInt(quantity, 10) || 0);

      this.calculateTotal();
    },
    calculateTotal(){
      if(this.isComplimentaryMode){
        this.$set(this.match, 'total', this.match.zones.reduce((sum, zone) => sum + Number(zone.quantity), 0));
      }
      else {
        this.$set(this.match, 'total', this.match.zones.reduce((sum, zone) => sum + zone.price * Number(zone.quantity), 0));
      }

      this.$emit('update');
    },
  }
}
</script>

<style lang="scss">
  @import "../../styles/main.scss";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins/breakpoints';

  .separator-border {
    border-top-width: 1px;
    border-top-style: solid;

    @include media-breakpoint-up(xl) {
      border-top-style: none;
      border-left-width: 1px;
      border-left-style: solid;
    }
  }

  .button-delete {
    height: auto !important;
    background: none;

    .button-component__icon {
      color: $danger-color !important;
      transform: none;
    }
  }

  .icon-submitted {
    font-size: 1.5rem;
    vertical-align: bottom !important;
  }

  .total {
    font-size: 14px;
  }

  .submitted {
    opacity: 0.5;
  }

  .noBr {
    white-space: nowrap;
  }

  .pointer {
    cursor: pointer;
  }

  .flex-grow {
    flex-grow: 1;
  }
</style>
